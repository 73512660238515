import { Fragment } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import DefaultLayout from "src/layout/DefaultLayout"

const AuthGuard = ({ routeElement, privatePage, authPage }) => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const isAdmin = useSelector((state) => state.auth.isAdmin)
    if (!isLoggedIn && authPage) {
        return (
            <Fragment>
                {routeElement}
            </Fragment>
        )
    }

    if (!isLoggedIn) {
        return <Navigate to={isAdmin ? "/admin/login" : "/login"} replace />
    }
    if (isLoggedIn && privatePage) {
        return (
            <DefaultLayout>
                {routeElement}
            </DefaultLayout>

        )
    }
    if (isLoggedIn && authPage) {
        return <Navigate to="/" replace />
    }
    return (
        <Fragment>
            {routeElement}
        </Fragment>
    )
}
export default AuthGuard