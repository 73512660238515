import React, { useState, useEffect } from 'react';
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormLabel,
  CFormInput,
  CRow,
  CToaster,
  CToast,
  CToastHeader,
  CToastBody,
} from '@coreui/react';
import { sendRequestOtpAdmin, sendOtpForAdminPass, changePasswordForAdmin } from 'src/services/adminServices/adminService';
import { useNavigate } from 'react-router-dom';

const OtpInput = ({ value, valueLength, onChange }) => {
  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    if (value.length >= maxLength) {
      const nextInput = document.querySelector(`input[name='otp${parseInt(name.slice(3)) + 1}']`);
      if (nextInput) {
        nextInput.focus();
      }
    }
    onChange(e);
  };

  return (
    <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-evenly' }}>
      {Array(valueLength)
        .fill('')
        .map((_, index) => (
          <CFormInput
            key={index}
            type="text"
            name={`otp${index + 1}`}
            maxLength={1}
            value={value[index] || ''}
            onChange={handleChange}
            style={{ width: '3rem', textAlign: 'center' }}
          />
        ))}
    </div>
  );
};

const AdminForgetPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const handleEnterKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (step === 1) handleEmailSubmit();
        if (step === 2) handleOtpSubmit();
        if (step === 3) handlePasswordSubmit();
      }
    };

    document.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [step, email, otp, newPassword]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { message, color, id: Math.random() }
    ]);
  };

  const handleOtpChange = (e) => {
    const otpValue = Array(6)
      .fill('')
      .map((_, i) => e.target.form[`otp${i + 1}`].value)
      .join('');
    setOtp(otpValue);
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailSubmit = () => {
    if (!email) {
      setError('Email is required');
    } else if (!validateEmail(email)) {
      setError('Please enter a valid email');
    } else {
      setLoading(true);
      sendRequestOtpAdmin({ email })
        .then((res) => {
          setError('');
          setInfoMessage(`An OTP has been sent to ${email}`);
          setStep(2);
        })
        .catch((err) => {
          setError('Failed to send OTP. Please try again.');
        })
        .finally(() => setLoading(false));
    }
  };

  const handleOtpSubmit = () => {
    if (!otp) {
      setError('OTP is required');
    } else if (otp.length !== 6) {
      setError('OTP must be 6 digits');
    } else {
      setLoading(true);
      setError('');
      sendOtpForAdminPass({ email, otp })
        .then((res) => {
          setInfoMessage('OTP verified successfully. Now, please enter your new password.');
          setStep(3);
        })
        .catch((err) => {
          setError('Invalid OTP. Please try again.');
        })
        .finally(() => setLoading(false));
    }
  };

  const handlePasswordSubmit = () => {
    if (!newPassword) {
      setError('New password is required');
    } else {
      setLoading(true);
      setError('');
      changePasswordForAdmin({ otp, newPassword, email })
        .then((res) => {
          setInfoMessage('Password has been successfully reset');
          setStep(1);
          setEmail('');
          setOtp('');
          setNewPassword('');
          if (res?.success === true) {
            addToast('Password has been successfully reset.', 'success');
            navigate('/admin/login');
          }
        })
        .catch((err) => {
          setError('Failed to reset password. Please try again.');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <CToaster style={{ position: 'fixed', top: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 1050 }}>
        {toasts.map((toast) => (
          <CToast key={toast.id} autohide={true} visible={true} color={toast.color}>
            <CToastHeader closeButton>
              <strong className="me-auto">Notification</strong>
            </CToastHeader>
            <CToastBody>{toast.message}</CToastBody>
          </CToast>
        ))}
      </CToaster>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard className="mx-4">
                <CCardBody className="p-4">
                  <CForm className="p-4">
                    <h1>Admin Forgot Password</h1>
                    {step === 1 && (
                      <>
                        <div className="mb-3">
                          <CFormLabel htmlFor="email">Email</CFormLabel>
                          <CFormInput
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </div>
                        {error && <CAlert color="danger">{error}</CAlert>}
                        <CButton color="success" className="w-100 text-light" onClick={handleEmailSubmit}>
                          {loading ? 'Sending...' : '2FA'}
                        </CButton>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        {infoMessage && <CAlert color="info">{infoMessage}</CAlert>}
                        <div className="mb-3 pb-3">
                          <CFormLabel htmlFor="otp">OTP</CFormLabel>
                          <OtpInput value={otp} valueLength={6} onChange={handleOtpChange} />
                        </div>
                        {error && <CAlert color="danger">{error}</CAlert>}
                        <CButton color="success" className="w-100 text-light" onClick={handleOtpSubmit}>
                          {loading ? 'Verifying...' : 'Verify 2FA'}
                        </CButton>
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <div className="mb-3">
                          <CFormLabel htmlFor="newPassword">New Password</CFormLabel>
                          <CFormInput
                            type="password"
                            id="newPassword"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={handlePasswordChange}
                          />
                        </div>
                        {error && <CAlert color="danger">{error}</CAlert>}
                        <CButton color="success" className="w-100 text-light" onClick={handlePasswordSubmit}>
                          {loading ? 'Resetting...' : 'Reset Password'}
                        </CButton>
                      </>
                    )}

                <span style={{cursor:"pointer",display:'flex',justifyContent:"center"}} className='text-center p-3' onClick={() => window.history.back()}>Back</span>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  );
};

export default AdminForgetPassword;
