import React, { useState, useEffect, useRef } from 'react';
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormLabel,
  CFormInput,
  CRow,
  CToaster,
  CToast,
  CToastHeader,
  CToastBody,
  CInputGroup,
  CInputGroupText
} from '@coreui/react';
import eye from "../../../assets/brand/eye.png";
import closeEye from "../../../assets/brand/eyebrow.png";
import { useNavigate } from 'react-router-dom';
import { sendRequestOtpOwner, sendOtpForOwnerPass, changePasswordForOwner } from 'src/services/ownerServices/ownerServices';

const OtpInput = ({ value, valueLength, onChange }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, valueLength);
  }, [valueLength]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    
    // Only allow numeric input
    if (/^\d*$/.test(value)) {
      onChange(e);

      // Move to next input if current field is filled
      if (value.length === 1 && index < valueLength - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (index > 0 && !value[index]) {
        // If current input is empty and backspace is pressed, move focus to previous input
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, valueLength);
    const numericData = pastedData.replace(/\D/g, '');
    
    const newOtp = value.split('');
    for (let i = 0; i < numericData.length; i++) {
      if (i < valueLength) {
        newOtp[i] = numericData[i];
      }
    }
    
    onChange({ target: { name: 'otp', value: newOtp.join('') } });

    // Focus on the next empty input or the last input
    const nextEmptyIndex = newOtp.findIndex(char => char === '');
    const focusIndex = nextEmptyIndex === -1 ? valueLength - 1 : nextEmptyIndex;
    inputRefs.current[focusIndex].focus();
  };

  return (
    <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-evenly' }}>
      {Array(valueLength).fill('').map((_, index) => (
        <CFormInput
          key={index}
          type="text"
          inputMode="numeric"
          pattern="\d*"
          name={`otp${index + 1}`}
          maxLength={1}
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={handleFocus}
          onPaste={handlePaste}
          ref={(ref) => (inputRefs.current[index] = ref)}
          style={{ width: '3rem', textAlign: 'center' }}
        />
      ))}
    </div>
  );
};

const OwnerForgetPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const handleEnterKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (step === 1) handleEmailSubmit();
        if (step === 2) handleOtpSubmit();
        if (step === 3) handlePasswordSubmit();
      }
    };

    document.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [step, email, otp, newPassword]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { message, color, id: Date.now() }
    ]);
  };

  const handleOtpChange = (e) => {
    if (e.target.name === 'otp') {
      // Handle paste event
      setOtp(e.target.value);
    } else {
      // Handle individual input change
      const index = parseInt(e.target.name.slice(3)) - 1;
      const newOtp = otp.split('');
      newOtp[index] = e.target.value;
      setOtp(newOtp.join(''));
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    if (password.includes(' ')) {
      setPasswordError('Password cannot contain spaces');
    } else {
      setPasswordError('');
      setNewPassword(password); // Only set the password if it doesn't contain spaces
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailSubmit = () => {
    if (!email) {
      setError('Email is required');
    } else if (!validateEmail(email)) {
      setError('Please enter a valid email');
    } else {
      setLoading(true);
      sendRequestOtpOwner({ email })
        .then((res) => {
          setError('');
          setInfoMessage(res?.message || `An OTP has been sent to ${email}`);
          setStep(2);
        })
        .catch((err) => {
          setError(err?.error || 'Failed to send OTP. Please try again.');
        })
        .finally(() => setLoading(false));
    }
  };

  const handleOtpSubmit = () => {
    if (!otp) {
      setError('OTP is required');
    } else if (otp.length !== 6) {
      setError('OTP must be 6 digits');
    } else {
      setLoading(true);
      setError('');
      sendOtpForOwnerPass({ email, otp })
        .then((res) => {
          setInfoMessage(res?.message || 'OTP verified successfully. Now, please enter your new password.');
          setStep(3);
        })
        .catch((err) => {
          setError(err?.message || 'Invalid OTP. Please try again.');
        })
        .finally(() => setLoading(false));
    }
  };

  const handlePasswordSubmit = () => {
    if (!newPassword) {
      setError('New password is required');
    } else if (newPassword.includes(' ')) {
      setError('Password cannot contain spaces');
    } else {
      setLoading(true);
      setError('');
      changePasswordForOwner({ otp, newPassword, email })
        .then((res) => {
          setInfoMessage(res?.message || 'Password has been successfully reset');
          if (res?.success === true) {
            addToast('Password has been successfully reset.', 'success');
            setTimeout(() => {
              navigate('/owner/login');
            }, 2000); // Delay navigation to allow toast to be seen
          }
        })
        .catch((err) => {
          console.log(err, "err3");
          setError(err?.error || 'Failed to reset password. Please try again.');
        })
        .finally(() => {
          setLoading(false);
          setStep(1);
          setEmail('');
          setOtp('');
          setNewPassword('');
        });
    }
  };

  return (
    <>
      <CToaster position="top-center">
        {toasts.map((toast) => (
          <CToast key={toast.id} autohide={true} visible={true} color={toast.color}>
            <CToastHeader closeButton>
              <strong className="me-auto">Notification</strong>
            </CToastHeader>
            <CToastBody>{toast.message}</CToastBody>
          </CToast>
        ))}
      </CToaster>

      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard className="mx-4">
                <CCardBody className="p-4">
                  <CForm className="p-4">
                    <h1>Member Forgot Password</h1>
                    {step === 1 && (
                      <>
                        <div className="mb-3">
                          <CFormLabel htmlFor="email">Email</CFormLabel>
                          <CFormInput
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </div>
                        {error && <CAlert color="danger">{error}</CAlert>}
                        <CButton color="success" className="w-100 text-light" onClick={handleEmailSubmit}>
                          {loading ? 'Sending...' : 'SUBMIT'}
                        </CButton>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        {infoMessage && <CAlert color="info">{infoMessage}</CAlert>}
                        <div className="mb-3 pb-3">
                          <CFormLabel htmlFor="otp">OTP</CFormLabel>
                          <OtpInput value={otp} valueLength={6} onChange={handleOtpChange} />
                        </div>
                        {error && <CAlert color="danger">{error}</CAlert>}
                        <CButton color="success" className="w-100 text-light" onClick={handleOtpSubmit}>
                          {loading ? 'Verifying...' : 'Verify OTP'}
                        </CButton>
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <div className="mb-3">
                          <CFormLabel htmlFor="newPassword">New Password</CFormLabel>
                          <CInputGroup>
                            <CFormInput
                              type={showPassword ? 'text' : 'password'}
                              id="newPassword"
                              placeholder="Enter new password"
                              value={newPassword}
                              onChange={handlePasswordChange}
                            />
                            <CInputGroupText onClick={toggleShowPassword}>
                              <img 
                                src={showPassword ? eye : closeEye} 
                                alt="toggle visibility" 
                                style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
                              />
                            </CInputGroupText>
                          </CInputGroup>
                          {passwordError && <CAlert color="danger" className="mt-2">{passwordError}</CAlert>}
                        </div>
                        {error && <CAlert color="danger">{error}</CAlert>}
                        <CButton color="success" className="w-100 text-light" onClick={handlePasswordSubmit}>
                          {loading ? 'Submitting...' : 'Submit New Password'}
                        </CButton>
                      </>
                    )}
                    <span style={{cursor:"pointer",display:'flex',justifyContent:"center"}} className='text-center p-3' onClick={() => window.history.back()}>Back</span>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  );
};

export default OwnerForgetPassword;