import Router from './routes/Router'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const App = () => {
  return (
    <Router />
  )
}

export default App
