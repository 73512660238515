
import { lazy } from "react";
import { adminRoutes } from "./adminRoutes";
import { ownerRoutes } from "./ownerRoutes";

const Dashboard = lazy(() => import("src/views/dashboard/Dashboard"))
const Page404 = lazy(() => import("src/views/pages/page404/Page404"))

export const routes = [
    {
        path: "/",
        element: <Dashboard />,
        privatePage: true
    },
    { path: '*', name: 'Error', element: <Page404 /> },
    ...adminRoutes,
    ...ownerRoutes
];
