import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter className='mt-4'>
      <div className='m-auto'>
        <span className="fw-semibold">&copy; 2024 ISHA Horse Registry.</span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
