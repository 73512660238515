import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: { sidebarShow: true, sidebarUnFoldable: false },
  reducers: {
    setSidebar: (state, action) => {
      return {
        ...state,
        sidebarShow: action.payload,
      };
    },
    toggleSidebarUnFoldable: (state) => {
      return {
        ...state,
        sidebarUnFoldable: !state.sidebarUnFoldable,
      };
    },


  },
});
export const { setSidebar, toggleSidebarUnFoldable } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
