import { cilLockLocked } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { constants } from 'src/constant/constants';
import { handleLogout } from 'src/services/authServices/authServices';
import AdminUpdateEmail from 'src/views/AdminUpdateEmail/AdminUpdateEmail';
import avatar from './../../assets/images/avatars/user.png';

const AppHeaderDropdown = () => {
  const [emailModalShow, setEmailModalShow] = useState(false);
  const userData = useSelector((state) => state.auth.userData);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const logoutHandler = async (e) => {
    e.preventDefault();
    await handleLogout();
  };

  const handleShowEmailModal = () => setEmailModalShow(true);
  const handleCloseEmailModal = () => setEmailModalShow(false);
  const handleSaveEmail = ({ email, otp }) => {
  
  };

  return (
    <>
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <img
            src={
              userData.profilePicture
            }
            alt="user"
            className="rounded-circle"
            width="42"
            height="42"
            style={{ objectFit: 'cover' }}
          />
        </CDropdownToggle>
        <CDropdownMenu placement="bottom-end">
          <CDropdownItem href="#" onClick={logoutHandler}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Log Out
          </CDropdownItem>

          {isAdmin && <CDropdownItem href="#" onClick={handleShowEmailModal}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Change Email
          </CDropdownItem>}
        </CDropdownMenu>
      </CDropdown>
      <AdminUpdateEmail
        show={emailModalShow}
        handleClose={handleCloseEmailModal}
        handleSave={handleSaveEmail}
      />
    </>
  );
};

export default AppHeaderDropdown;
