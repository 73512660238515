import { CContainer } from '@coreui/react'
import { Fragment, memo } from 'react'

const AppContent = ({ children }) => {
  return (
    <CContainer lg>
      <Fragment>
        {children}
      </Fragment>
    </CContainer>
  )
}

export default memo(AppContent)
