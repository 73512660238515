import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CIcon from '@coreui/icons-react'
import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'

import { AppSidebarNav } from './AppSidebarNav'

import IshaLogo from 'src/assets/brand/isha-logo.png'
import IshaLine from 'src/assets/brand/isha-line.png'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import { setSidebar, toggleSidebarUnFoldable } from 'src/redux/reducers/sidebarSlice'
import { adminNavItems, ownerNavItems } from '../navItems'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unFoldable = useSelector((state) => state.sidebar.sidebarUnFoldable)
  const sidebarShow = useSelector((state) => state.sidebar.sidebarShow)
  const isAdmin = useSelector((state) => state.auth.isAdmin)

  const navigation = useMemo(() => {
    if (isAdmin) {
      return adminNavItems
    } else {
      return ownerNavItems
    }
  }, [isAdmin])

  return (
    <CSidebar
      position="fixed"
      unfoldable={unFoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(setSidebar(visible))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex flex-column p-2 gap-1" to="/">
        <CImage style={{filter:"brightness(0) invert(1)"}} src={IshaLogo} className='' height={45} />
        <CImage style={{filter:"brightness(0) invert(1)"}} src={IshaLine}   className='mw-100 ' />
        
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(toggleSidebarUnFoldable())}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
