import React, { useState, useEffect } from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CForm,
  CFormLabel,
  CFormInput,
  CAlert,
  CToaster,
  CToast,
  CToastBody,
  CToastHeader,
} from '@coreui/react';
import { ChangeEmailForAdminSave, changeEmailForAdmin } from 'src/services/adminServices/adminService';
import { logoutUser } from 'src/redux/reducers/authSlice';
import { useDispatch } from 'react-redux';

const AdminUpdateEmail = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [error, setError] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (show) {
      setStep(1);
      setEmail('');
      setOtp(new Array(6).fill(''));
      setError('');
      setInfoMessage('');
    }
  }, [show]);

  useEffect(() => {
    const handleEnterKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (step === 1) handleEmailSubmit();
        if (step === 2) handleOtpSubmit();
      }
    };

    document.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [step, email, otp]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (element, index) => {
    if (/^\d*$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      if (element.nextSibling && element.value) {
        element.nextSibling.focus();
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const addToast = (message, color) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { message, color, id: Math.random() }
    ]);
  };

  const handleEmailSubmit = () => {
    if (!email) {
      setError('Email is required');
    } else if (!validateEmail(email)) {
      setError('Please enter a valid email');
    } else {
      setError('');
      setLoading(true);
      changeEmailForAdmin({ email })
        .then((res) => {
          setLoading(false);
          setInfoMessage(`An OTP has been sent to ${email}`);
          setStep(2);
          addToast('OTP has been sent to your email.', 'success');
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          addToast('Failed to send OTP. Please try again.', 'danger');
        });
    }
  };

  const handleOtpSubmit = () => {
    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      setError('Please enter a valid 6-digit OTP');
    } else {
      setError('');
      setLoading(true);
      ChangeEmailForAdminSave({ email, otp: otpValue })
        .then((res) => {
          setLoading(false);
          if (res?.success === true) {
            addToast(res?.message, 'success');
            setOtp(new Array(6).fill(''));
            setEmail('');
            handleClose();
            dispatch(logoutUser());
            
          }
          else{
            console.log(res);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err?.error);
          setError(err?.error);
          // addToast('Failed to save changes. Please try again.', 'danger');
        });
    }
  };

  return (
    <>
      <CToaster style={{
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1050,
      }}>
        {toasts.map((toast) => (
          <CToast key={toast.id} autohide={true} visible={true} color={toast.color}>
            <CToastHeader closeButton>
              <strong className="me-auto">Notification</strong>
            </CToastHeader>
            <CToastBody>{toast.message}</CToastBody>
          </CToast>
        ))}
      </CToaster>
      <CModal visible={show} onClose={handleClose}>
        <CModalHeader onClose={handleClose}>
          <CModalTitle>Change Email</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            {step === 1 && (
              <>
                <div className="mb-3">
                  <CFormLabel htmlFor="email">New Email</CFormLabel>
                  <CFormInput
                    type="email"
                    id="email"
                    placeholder="Enter new email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                {error && <CAlert color="danger">{error}</CAlert>}
              </>
            )}
            {step === 2 && (
              <>
                {infoMessage && <p className="text-muted">An OTP has been sent to {email}</p>}
                <div className="mb-3">
                  <CFormLabel htmlFor="otp">OTP</CFormLabel>
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                    {otp?.map((value, index) => (
                      <CFormInput
                        key={index}
                        type="text"
                        value={value}
                        maxLength="1"
                        onChange={(e) => handleOtpChange(e.target, index)}
                        style={{ width: '40px', textAlign: 'center' }}
                      />
                    ))}
                  </div>
                </div>
                {error && <CAlert color="danger">{error}</CAlert>}
              </>
            )}
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={handleClose}>
            Close
          </CButton>
          {step === 1 && (
            <CButton color="primary" onClick={handleEmailSubmit}>
              {loading ? 'Sending OTP...' : 'Send OTP'}
            </CButton>
          )}
          {step === 2 && (
            <CButton color="primary" onClick={handleOtpSubmit}>
              {loading ? 'Saving Changes...' : 'Save Changes'}
            </CButton>
          )}
        </CModalFooter>
      </CModal>
    </>
  );
};

export default AdminUpdateEmail;
