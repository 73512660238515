import { constants } from "../../constant/constants";
import { logoutUser } from "../../redux/reducers/authSlice";
import {
  postRequest,
  removeFromLocalStorage,
  setAccessTokenInLocal,
  setUserRoleInLocal,
} from "../helper";

import store from "../../redux/store";
export const handleRegister = async (payload) => {
  try {
    const res = await postRequest(constants.endPoints.register, payload);
  } catch (e) {
    throw e;
  }
};

export const handleAdminLogin = async (payload) => {
  try {
    const res = await postRequest(
      constants.endPoints.adminEndPoints.adminLogin,
      payload
    );
    setAccessTokenInLocal(res.data.data.token);
    setUserRoleInLocal(res.data.data.role);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const handleOwnerLogin = async (payload) => {
  try {
    const res = await postRequest(
      constants.endPoints.ownerEndPoints.ownerLogin,
      payload
    );
    // console.log(res.data)
    setAccessTokenInLocal(res.data.data.token);
    setUserRoleInLocal(res.data.data.role);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const handleOtpSend = async (payload) => {
  try {
    const res = await postRequest(
      constants.endPoints.ownerEndPoints.otpLogin,
      payload
    );
    // setAccessTokenInLocal(res.data.data.token)
    // setUserRoleInLocal(res.data.data.role);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const handleLogout = async () => {
  try {
    removeFromLocalStorage(constants.accessToken);
    removeFromLocalStorage(constants.userRole);
    removeFromLocalStorage(constants.userData);
    store.dispatch(logoutUser());
  } catch (e) {
    throw e;
  }
};
