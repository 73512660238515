import axiosInstance from "../axios/axiosInstance"
import { constants } from "../constant/constants"

export const getRequest = async (endPoint, params) => {
    try {
        const res = await axiosInstance.get(endPoint, { params },)
        return res
    } catch (e) {
        throw e?.data
    }
}

export const postRequest = async (endPoint, payload) => {
    try {
        const res = await axiosInstance.post(endPoint, payload)
        return res
    } catch (e) {
        throw e?.data
    }
}

export const putRequest = async (endPoint, payload) => {
    try {
        const res = await axiosInstance.put(endPoint, payload)
        return res
    } catch (e) {
        throw e?.data
    }
}

export const patchRequest = async (endPoint, payload) => {
    try {
        const res = await axiosInstance.patch(endPoint, payload)
        return res
    } catch (e) {
        throw e?.data
    }
}

export const deleteRequest = async (endPoint) => {
    try {
        const res = await axiosInstance.delete(endPoint)
        return res
    } catch (e) {
        throw e?.data
    }
}

// export const setInLocalStorage = (keyName, data) => {
//     data && localStorage.setItem(keyName, JSON.stringify(data))
// }

// export const getFromLocalStorage = (keyName) => {
//     try {
//         return JSON?.parse(localStorage.getItem(keyName))
//     } catch {
//         return null
//     }
// }

export const setInLocalStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error("Error saving to local storage", error);
    }
}


export const getFromLocalStorage = (key) => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) {
            return null;
        }
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error("Error fetching from local storage", error);
        return null;
    }
}

export const removeFromLocalStorage = (keyName) => {
    return localStorage.removeItem(keyName)
}

export const clearAllLocalStorage = () => {
    return localStorage.clear()
}

export const setAccessTokenInLocal = (accessToken) => {
    accessToken && localStorage.setItem(constants.accessToken, accessToken)
}

export const getAccessToken = () => {
    return localStorage.getItem(constants.accessToken)
}

export const setUserDataInLocal = (userData) => {
    setInLocalStorage(constants.userData, userData)
}

export const setUserRoleInLocal = (userRole) => {
    setInLocalStorage(constants.userRole, userRole)
}

export const objectToFormData = (obj) => {
    const form_data = new FormData();

    for (var key in obj) {
        form_data.append(key, obj[key]);
    }
    return form_data
}